export const APP_URL = 'https://user.phonetag.co';
export const VCARD_FUNC_ENDPOINT = 'https://us-central1-tappin-nfc.cloudfunctions.net/getVcard';

export const socialLinkIcons = {
  instagram: {
    logo: 'instagram_2x.png',
  },
  snapchat: {
    logo: 'snapchat_2x.png',
  },
  twitter: {
    logo: 'twitter_2x.png',
  },
  facebook: {
    logo: 'facebook_2x.png',
  },
  linkedin: {
    logo: 'linkedin_2x.png',
  },
  phone_number: {
    logo: 'phone_number_2x.png',
  },
  email: {
    logo: 'mailicon_2x.png',
  },
  youtube: {
    logo: 'youtube_2x.png',
  },
  tiktok: {
    logo: 'tiktoknew_2x.png',
  },
  soundcloud: {
    logo: 'soundcloudnew_2x.png',
  },
  spotify: {
    logo: 'spotify_2x.png',
  },
  apple_music: {
    logo: 'applemusicicon_2x.png',
  },
  venmo: {
    logo: 'venmo_2x.png',
  },
  cash_app: {
    logo: 'cash_2x.png',
  },
  paypal: {
    logo: 'paypal_2x.png',
  },
  whatsapp: {
    logo: 'whatsapp_2x.png',
  },
  twitch: {
    logo: 'twitchicon_new_2x.png',
  },
  custom_link: {
    logo: 'customlink_2x.png',
  },
  website: {
    logo: 'website_2x.png',
  },
  business_address: {
    logo: 'googlemaps.png',
  },
  contact_card: {
    logo: 'contact_card_2x.png',
  },
};
