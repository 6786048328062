import React from 'react';
import { android, iOS } from '../../uitls';
import './ContactCardPopup.css';



const messageVariants = {
  ios: "Click 'Create New Contact' to save this Contact Card",
  android: 'Click Download then Open the file and click Save to store the Contact Card.',
};
const message = android() ? messageVariants.android : iOS() ? messageVariants.ios : null;

const ContactCardPopup = ({ visible, url, onClick }) => {
  if (!message) {
    return null;
  }
  return (
    <div className={`ContactCardPopup ${visible ? 'visible' : ''}`}>
      <b>{message}</b>

      <p>
        <a href={url} onClick={onClick}>
          {' '}
          Ok{' '}
        </a>
      </p>
    </div>
  );
};

export default ContactCardPopup;
