import React from 'react';
import { withRouter } from 'react-router';
import './Header.css';

const Header = () => {
  return (
    <div className="header-wrapper">
      <div className="main-container">
        {/*<div className='main-col' style={{textAlign: 'left'}}>*/}
        {/*  <a className='login-nav'><b>Login</b></a>*/}
        {/*</div>*/}

        <div className="main-col">
          <img src="/logo.svg" className="img-logo" />
        </div>

        {/*<div className='main-col' style={{textAlign: 'right'}}>*/}
        {/*  <a className='join-nav'><b>Join</b></a>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default withRouter(Header);
