import React from 'react';
import { withRouter } from 'react-router';

import { socialLinkIcons } from '../../constants';
import { linkKeys, linkLabels } from '../../constants/link-builder';

import './SocialItem.css';

const SocialItem = ({ socialType = '', item, onClick }) => {
  const socialTypeLower = socialType.toLowerCase();
  const socialImg = socialLinkIcons[socialTypeLower] ? socialLinkIcons[socialTypeLower].logo : '';
  const socialTitle = linkLabels[socialType] || '';

  if (socialType === 'hidden_item') {
    return (
      <div className="grid-square" style={{ visibility: 'hidden' }}>
        <a className="social" href={'#'} rel="noopener noreferrer">
          <div className="social--logo-wrapper">
            <img className="social--logo" src={`/social/${socialImg}`} alt="Social Icon" />
          </div>
          <div className="social--label">Hidden</div>
        </a>
      </div>
    );
  }

  if (socialType === linkKeys.contact_card) {
    return (
      <div className="grid-square">
        <a className="social" href={item.url} rel="noopener noreferrer" onClick={onClick}>
          <div className="social--logo-wrapper">
            <img className="social--logo" src={`/social/${socialImg}`} alt="Social Icon" />
          </div>
          <div className="social--label">{socialTitle}</div>
        </a>
      </div>
    );
  }

  if (socialType === linkKeys.custom_link) {
    return (
      <div className="grid-square">
        <a className="social" href={item?.url || '#'} target="_blank" rel="noopener noreferrer">
          <div className="social--logo-wrapper is-circle">
            <img className="social--logo" src={`/social/${socialImg}`} alt="Social Icon" />
          </div>
          <div className="social--label">{item.label ? item.label : socialTitle}</div>
        </a>
      </div>
    );
  }
  if (socialType === linkKeys.website) {
    return (
      <div className="grid-square">
        <a className="social" href={item?.url || '#'} target="_blank" rel="noopener noreferrer">
          <div className="social--logo-wrapper is-circle">
            <img className="social--logo" src={`/social/${socialImg}`} alt="Social Icon" />
          </div>
          <div className="social--label">{item.label ? item.label : socialTitle}</div>
        </a>
      </div>
    );
  }

  if (socialType === linkKeys.phone_number) {
    return (
      <div className="grid-square">
        <a
          className="social"
          href={'sms:' + item.country.dial_code + item?.value}
          rel="noopener noreferrer">
          <div className="social--logo-wrapper">
            <img className="social--logo" src={`/social/${socialImg}`} alt="Social Icon" />
          </div>
          <div className="social--label">{socialTitle}</div>
        </a>
      </div>
    );
  }

  return (
    <div className="grid-square">
      <a className="social" href={item?.url || '#'} rel="noopener noreferrer">
        <div className="social--logo-wrapper">
          <img className="social--logo" src={`/social/${socialImg}`} alt="Social Icon" />
        </div>
        <div className="social--label">{socialTitle}</div>
      </a>
    </div>
  );
};

export default SocialItem;
