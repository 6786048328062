export function iOS() {
    return (
      ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
        navigator.platform,
      ) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
  }
  
 export function android() {
    const isAndroid = /(android)/i.test(navigator.userAgent);
    const isMobile = /(mobile)/i.test(navigator.userAgent);
    return isAndroid && isMobile;
  }