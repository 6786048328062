export const linkKeys = {
  instagram: 'instagram',
  snapchat: 'snapchat',
  twitter: 'twitter',
  facebook: 'facebook',
  phone_number: 'phone_number',
  linkedin: 'linkedin',
  email: 'email',
  youtube: 'youtube',
  tiktok: 'tiktok',
  soundcloud: 'soundcloud',
  spotify: 'spotify',
  apple_music: 'apple_music',
  venmo: 'venmo',
  venmo_browser: 'venmo_browser',
  cash_app: 'cash_app',
  paypal: 'paypal',
  whatsapp: 'whatsapp',
  twitch: 'twitch',
  custom_link: 'custom_link',
  website: 'website',
  business_address: 'business_address',
  add_link: 'add_link',
  contact_card: 'contact_card',
};

export const linkLabels = {
  [linkKeys.add_link]: 'Add Link',
  [linkKeys.apple_music]: 'Apple Music',
  [linkKeys.business_address]: 'Address',
  [linkKeys.cash_app]: 'CashApp',
  [linkKeys.contact_card]: 'Contact Card',
  [linkKeys.custom_link]: 'Custom Link',
  [linkKeys.email]: 'Email',
  [linkKeys.facebook]: 'Facebook',
  [linkKeys.instagram]: 'Instagram',
  [linkKeys.linkedin]: 'LinkedIn',
  [linkKeys.spotify]: 'Spotify',
  [linkKeys.paypal]: 'PayPal',
  [linkKeys.phone_number]: 'Text',
  [linkKeys.snapchat]: 'Snapchat',
  [linkKeys.soundcloud]: 'SoundCloud',
  [linkKeys.tiktok]: 'TikTok',
  [linkKeys.twitch]: 'Twitch',
  [linkKeys.twitter]: 'Twitter',
  [linkKeys.venmo]: 'Venmo',
  [linkKeys.website]: 'Website',
  [linkKeys.whatsapp]: 'WhatsApp',
  [linkKeys.youtube]: 'YouTube',
};
