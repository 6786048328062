import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router';
import { firebaseApp, storage } from '../firebase';
import Loading from '../Components/Loading/Loading';
import SocialItem from '../Components/SocialItem/SocialItem';
import './Home.css';
import { Helmet } from 'react-helmet';
import { linkKeys } from '../constants/link-builder';
import { VCARD_FUNC_ENDPOINT } from '../constants';
import ContactCardPopup from '../Components/ContactCardPopup/ContactCardPopup';
import { android, iOS } from '../uitls';

const Home = ({ match }) => {
  const { uid } = match.params;

  const [userData, setUserData] = useState(null);
  const [userAvatar, setAvatar] = useState('./logo_default.jpg');
  const [contactPopUpVisible, setContactPopUpVisible] = useState(false);

  const linkCountRemainder = useMemo(() => {
    if (userData) {
      return userData.links.length && userData.links.length % 3;
    }
    return 0;
  }, [userData]);

  const generateVCard = useCallback(() => {
    window.location.href = window.location.origin + '/appclip-vcard/' + uid;
  }, [uid]);

  const toSocial = useCallback(async () => {
    try {
      const response = await firebaseApp.functions().httpsCallable('getPublicProfile')({
        uid: uid,
      });

      if (response.data.direction !== null) {
        if (response.data.direction === 'contact') {
          setContactPopUpVisible(true);
        } else {
          window.location.href = response.data.direction;
        }
      }
      setUserData(response.data);
    } catch (e) {}
  }, [uid]);

  useEffect(() => {
    getUrl(uid).then((result) => {
      return result ? setAvatar(result) : null;
    });
    toSocial();
  }, [toSocial, uid]);

  const getUrl = async (uid) => {
    return (await storage.ref(`users/${uid}-avatar`).getDownloadURL()) || null;
  };

  if (!userData) {
    return <Loading />;
  }

  return (
    <div className="container">
      <Helmet titleTemplate="%s | PhoneTag.co">
        <meta property="og:title" content={`${userData.profileName || 'Not Found'} PhoneTag.co`} />
        <meta
          name="apple-itunes-app"
          content="app-id=1538474690, app-clip-bundle-id=com.tappin.phonetag.Clip"
        />
        <title>{userData.profileName || 'Not Found'}</title>
      </Helmet>
      <ContactCardPopup
        visible={contactPopUpVisible}
        url={`${VCARD_FUNC_ENDPOINT}?uid=${uid}`}
        onClick={() => setContactPopUpVisible(false)}
      />
      <div className="row">
        <div className="content">
          <div className="profile">
            <div className="profile-image">
              {userAvatar ? (
                <div className="user-photo--fakeBorder">
                  <div
                    className="user-photo"
                    style={{
                      background: `url(${userAvatar}) center center / cover`,
                    }}
                  />
                </div>
              ) : (
                <i className="fa fa-user-circle default-photo" />
              )}
            </div>
            <div className="profile-name">{userData.profileName || ' '}</div>
            {userData.bioDescription && <div className="bio">{userData.bioDescription}</div>}
          </div>

          <div className="grid">
            {_.map(_.orderBy(userData.links, 'position'), (item) => {
              return (
                <SocialItem
                  socialType={item.name}
                  onClick={
                    item.name === linkKeys.contact_card
                      ? (e) => {
                          setContactPopUpVisible(true);
                        }
                      : undefined
                  }
                  item={
                    item.name === linkKeys.contact_card
                      ? {
                          ...item,
                          url: android() || iOS() ? '#' : `${VCARD_FUNC_ENDPOINT}?uid=${uid}`,
                        }
                      : item
                  }
                  key={item.name}
                />
              );
            })}

            {linkCountRemainder === 2 ? (
              <SocialItem socialType={'hidden_item'} />
            ) : (
              <>
                <SocialItem socialType={'hidden_item'} />
                <SocialItem socialType={'hidden_item'} />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(Home);
