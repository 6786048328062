import React from 'react';
import { withRouter } from 'react-router';
import './Banner.css';

const Banner = () => {
  const open = () => {
    window.open('https://www.phonetag.co/shop');
  };
  return (
    <div className="banner-wrapper" onClick={open}>
      <div className="content-container">
        <div className={'cta-img'} />
        <p className="cta">Click here to buy a Phone Tag</p>
        <img className={'cta-img'} src={'/linkico.svg'} />
      </div>
    </div>
  );
};

export default withRouter(Banner);
