import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';
import 'firebase/database';
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: 'AIzaSyC3Mgcd1YQAVdc45Ne79x27_v9ux2h8d_g',
  authDomain: 'tappin-nfc.firebaseapp.com',
  databaseURL: 'https://tappin-nfc.firebaseio.com',
  projectId: 'tappin-nfc',
  storageBucket: 'tappin-nfc.appspot.com',
  messagingSenderId: '419840883187',
  appId: '1:419840883187:web:e82959c7184a8f331eba86',
  measurementId: 'G-JNECD369Y5',
};

export const firebaseApp = app.initializeApp(firebaseConfig);

// Uncomment lines below if you want to use functions from your local environment:
// if(process.env.NODE_ENV === "development"){
//  app.functions().useFunctionsEmulator("http://192.168.0.130:5000");
// }

export const db = firebaseApp.firestore();
export const storage = firebaseApp.storage();
