import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import ContactCardPopup from '../Components/ContactCardPopup/ContactCardPopup';
import { VCARD_FUNC_ENDPOINT } from '../constants';
import './Home.css';

const VCSIframe = React.memo(({ uid }) => (
  <iframe
    src={`${VCARD_FUNC_ENDPOINT}?uid=${uid}`}
    style={{
      width: 0,
      height: 0,
      display: 'none',
    }}
    id="fileFrame"
    title="Contact Card"></iframe>
));

const AppClipVCardPage = ({ match }) => {
  const { uid } = match.params;
  
  const handleClick = () =>
    setTimeout(() => {
      document.location = 'https://phonetag.co';
    }, 8000);

  return (
    <div>
      <ContactCardPopup
        visible={true}
        url={`${VCARD_FUNC_ENDPOINT}?uid=${uid}`}
        onClick={handleClick}
      />
    </div>
  );
};

export default withRouter(AppClipVCardPage);
