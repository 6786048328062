import React from 'react';
import './Home.css';

const NotFoundPage = () => {
  return (
    <div className="container container--notFound">
      <div className="row">
        <div className="content">
          <div className="social-form">
            <div
              className="grid"
              style={{ textAlign: 'center', margin: '0 auto', display: 'block' }}>
              <b>User Not Found.</b>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFoundPage;
