import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { withRouter } from 'react-router';

import HomePage from '../../pages/Home.page';
import AppClipVCardPage from '../../pages/AppClipVCard.page';
import NotFoundPage from '../../pages/NotFound.page';
import Header from '../Header/Header';

import './App.css';
import Banner from '../Banner/Banner';

const App = () => {
  return (
    <div className="app-container">
      <Header />
      <Banner />
      <Switch>
        <Route path="/appclip-vcard/:uid" component={AppClipVCardPage} exact />
        <Route path="/:uid" component={HomePage} exact />
        <Route component={NotFoundPage} path="*" />
      </Switch>
    </div>
  );
};

export default withRouter(App);
